.vertical-timeline-element-content {
  padding: 2em 3em !important;
  background: rgba(0, 0, 0, 0.3);
    border: 1px solid #4db5ff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.vertical-timeline-element-content:hover{
  background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}

.vertical-timeline-element-content-arrow {
  border-right: 7px solid var(--color-primary);
}

.vertical-timeline-element-title {
  font-family: "Arial Black", sans-serif;
  font-size: 24px;
  line-height: initial;
}

.vertical-timeline-element-client {
  color: var(--color-primary);
}

.vertical-timeline-element-icon {
  -webkit-box-shadow: 0 0 0 4px #fff, 0 3px 0 4px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.vertical-timeline-element-icon:hover {
  transform: scale(1.1);
}

.date {
  color: rgb(201, 251, 255);
  letter-spacing: 3px;
    word-spacing: 5px;
}

#description {
  margin: 1.5em 0 2em 0;
}

.button {
  text-decoration: none;
  padding: 0.5em 1em;
  border-radius: 5px;
  color: var(--color-white);
  background-color: var(--color-primary);
}

.button:hover {
  color: black;
  background: white;
}



@media only screen and (max-width: 1700px) {
  .vertical-timeline-element-date {
    display: block !important;
    float: none !important;
    color: var(--color-light);
  }
}

/* .experience__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}

.experience__container > div {
    background: var(--color-bg-variant);
    padding: 2.4rem 5rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.experience__container > div:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}

.experience__container > div h3 {
    text-align: center;
    margin-bottom: 2rem;
    color: var(--color-primary);
}

.experience__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 2rem;
}

.experience__details {
    display: flex;
    gap: 1rem;
}

.experience__details-icon {
    margin-top: 6px;
    color: var(--color-primary);
    font-size: x-large;
}

@media screen and (max-width: 1024px) {
    .experience__container {
        grid-template-columns: 1fr;
    } 

    .experience__container > div {
        width: 80%;
        padding: 2rem;
        margin: 0 auto;
    }

    .experience__content {
        padding: 1rem;
    }
}

@media screen and (max-width: 600px) {
    .experience__container {
        gap: 1rem;
    }

    .experience__container > div {
        width: 100%;
        padding: 2rem 1rem; 
    }

} */

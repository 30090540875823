.about__container {
  display: grid;
  grid-template-columns: 35% 50%;
  gap: 15%;
  justify-items: start;
}


.about__me {
  width: 90%;
  aspect-ratio: 1/1.2;
  border-radius: 4rem;
  background: linear-gradient(
    45deg,
    transparent,
    var(--color-primary),
    transparent
  );
  display: grid;
  place-items: center;
  animation-name: fadeRight;
  animation-duration: 1000ms;
  animation-delay: 0;
  animation-timing-function: linear;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.about__me-image {
  border-radius: 4rem;
  overflow: hidden;
  transform: rotate(10deg);
  transition: var(--transition);
}

.about__me-image:hover {
  transform: rotate(0);
}

.about__cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
}

.about__card {
  border-radius: 1rem;
  padding: 2rem;
  text-align: center;
  min-width: 174px;
  background: rgba(0, 0, 0, 0.3);
    border: 1px solid #4db5ff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.about__card:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
  cursor: default;
}

.about__icon {
  color: var(--color-primary);
  font-size: 1.4rem;
  margin-bottom: 1rem;
}

.about_-card h5 {
  font-size: 0.95rem;
}

.about__card small {
  font-size: 0.7rem;
  color: var(--color-light);
}

.about__content p {
  margin: 2rem 0 2.6rem;
  color: var(--color-light);
}

a:hover {
  color: var(--color-white);
}
/* -------------MEDIA QUERIES (MEDIUM DEVICES)---------------------- */
@media screen and (max-width: 1024px) {
  .about__container {
    grid-template-columns: 1fr;
    gap: 0;
  }

  .about__me {
    width: 35%;
    margin: 2rem auto 4rem;
  }

  .about__content p {
    margin: 1rem 0 1.5rem;
  }
}

/* -------------MEDIA QUERIES (SMALL DEVICES)---------------------- */
@media screen and (max-width: 600px) {
  .about__me {
    width: 55%;
    margin: 0 auto 3rem;
  }

  .about__cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1.5rem;
  }

  .about__content {
    text-align: center;
  }

  .about__content p {
    margin: 1.5rem;
  }
}

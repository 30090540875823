.portfolio__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
}

.portfolio__item {
  background: var(--color-bg-variant);
  border: 1px solid transparent;
  border-radius: 16px;
  transition: var(--transition);
}

.portfolio__item:hover {
  border-color: var(--color-primary-variant);
  background: transparent;
}

.portfolio__item-image img {
  height: 300px;
}

.portfolio__item h3 {
  text-align: center;
  padding-bottom: 10px;
  font-family: "Arial Black", sans-serif;
  font-size: 18px;
  padding: 8px 0px;
}

.portfolio__item-cta {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 1rem;
  margin-bottom: 1rem;
}

.portfolio__container {
  display: flex;
    flex-wrap: wrap;
    margin: 0px 10px;
    justify-content: center;
}

.portfolio__item {
  position: relative;
  border: 1px solid transparent;
  border-radius: 16px;
  transition: var(--transition);
  width: 446px;
  background: rgba(0, 0, 0, 0.3);
  border: 1px solid #4db5ff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.portfolio__item:hover {
  border-color: var(--color-primary-variant);
  background: transparent;
  transform: scale(1.05);
}

.portfolio__item-image {
  position: relative;
  overflow: hidden;
}

.portfolio__item-image img {
  width: 100%;
  height: 240px;
  opacity: 1;
  transition: opacity 0.3s, filter 0.3s; /* Agregamos la transición para el desenfoque */
}

.portfolio__item:hover .portfolio__item-image img {
  opacity: 0.8;
  filter: blur(5px); /* Aplicamos un desenfoque de 5 píxeles */
}

.portfolio__item-bottom-card {
  padding: 8px;
}

.portfolio__item-cta {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.3s;
}

.portfolio__item:hover .portfolio__item-cta {
  opacity: 1;
}

.portfolio__item-bottom {
  display: flex;
  flex-direction: column;
}

.portfolio__item-technologies {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.technology-icon {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.technology-name {
  font-variant: all-petite-caps;
}

.buttons {
  display: flex;
  gap: 15px;
}

/* -------------MEDIA QUERIES (MEDIUM DEVICES)---------------------- */
@media screen and (max-width: 1024px) {
  .portfolio__container {
    grid-template-columns: 1fr 1fr;
    gap: 1.2rem;
  }
}

/* -------------MEDIA QUERIES (SMALL DEVICES)---------------------- */
@media screen and (max-width: 600px) {
  .portfolio__container {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}

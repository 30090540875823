/* 



.home.content h1 {
  font-size: 56px;
  font-weight: 700;
  line-height: 1.2;
}

.home-content h3 {
  font-size: 32px;
  font-weight: 700;
  color: #00abf0;
}

.home-content p {
  font-size: 16px;
  margin: 20px 0 40px;
}

.home-content .btn-box {
  display: flex;
  justify-content: space-between;
  width: 345px;
  height: 50px;
}

.btn-box a {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 100%;
  background: #00abf0;
  border: 2px solid #00abf0;
  border-radius: 8px;
  font-size: 19px;
  color: #081b29;
  text-decoration: none;
  font-weight: 600;
  letter-spacing: 1px;
  z-index: 1;
  overflow: hidden;
  transition: 0.5s;
}

.btn-box a:hover {
  color: #00abf0;
}

.btn-box a:nth-child(2) {
  background: transparent;
  color: #00abf0;
}

.btn-box a:nth-child(2):hover {
  color: #081b29;
}

.btn-box a:nth-child(2)::before {
  background: #00abf0;
}

.btn-box a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: #081b29;
  z-index: -1;
  transition: 0.5s;
}

.btn-box a:hover::before {
  width: 100%;
} */

.home {
  height: 100vh;
  display: flex;
  align-items: center;
  padding: 0 10%;
}

header {
  height: 85vh;
  padding-top: 2rem;
  overflow: hidden;
}

h1,
h5,
img {
  user-select: none;
}

.header__container {
  text-align: center;
  height: 100%;
  position: relative;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
}

.header_text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.header_text h5 {
  font-size: x-large;
  color: var(--color-light);
}

.nombre,
.apellido {
  font-size: xxx-large;
}

.fa-hand-paper:before {
  content: "\f256";
  font-size: 24px;
  padding-right: 5px;
  color: hsla(39, 100%, 68%, 1);
}

/* ------------ CTA ------------------ */
.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 1.2rem;
}

/* ------------ HEADER SOCIALS ------------------ */
.header__socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  left: 0;
  bottom: 8.5rem;
  font-size: 1.5rem;
}

.header__socials::after {
  content: "";
  width: 1px;
  height: 2rem;
  background: var(--color-primary);
}

/* ------------ ME ------------------ */
.me {
  background: url(https://iili.io/HtxncTG.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  box-shadow: inset 0 0 0 9px rgb(255 255 255 /30%);
  order: 1;
  justify-self: center;
  width: 300px;
  height: 300px;
  animation: profile__animate 8s ease-in-out infinite 1s;
}

@keyframes profile__animate {
  0% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }
  50% {
    border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
  }
  100% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }
}

/* ------------ SCROLL DOWN ------------------ */
.scroll__down {
  position: absolute;
  right: -2.3rem;
  bottom: 8rem;
  transform: rotate(90deg);
  font-weight: 300;
  font-size: 0.9rem;
}

/* -------------MEDIA QUERIES (MEDIUM DEVICES)---------------------- */
@media screen and (max-width: 1024px) {
  .header {
    height: 68vh;
  }

  .header__socials {
    display: none;
  }
}

/* -------------MEDIA QUERIES (SMALL DEVICES)---------------------- */
@media screen and (max-width: 800px) {
  section {
    margin-top: 6rem;
  }

  .header {
    height: 110vh;
  }

  .header__container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 25px;
  }

  .header__socials {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2.8rem;
    bottom: 0rem;
    width: -webkit-fill-available;
    font-size: 1.5rem;
    justify-content: center;
  }

  .nombre,
  .apellido {
    font-size: xx-large;
  }

  .me {
    width: 200px;
    height: 200px;
  }

  .scroll__down,
  .header__socials::after {
    display: none;
  }
}
